import {
	createRouter,
	createWebHistory
} from 'vue-router';


import home from '../views/home.vue'
import launch from '../views/launch.vue'
import token from '../views/token.vue'
import fomo3d from '../views/fomo3d.vue'
// import ranking from '../views/ranking.vue'

const routes = [{
		path: '/',
		name: 'home',
		component: home,
	},
	{
		path: '/launch',
		name: 'launch',
		component: launch,
	},
	{
		path: '/token/:address',
		name: 'token',
		component: token,
	},
	{
		path: '/fomo3d',
		name: 'fomo3d',
		component: fomo3d,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return {
			top: 0
		};
	}
});

export default router;