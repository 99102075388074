<template>
	<div class="token">
		<div class="content">
			<div class="token-info-pc">
				<div class="logo-meta-link">
					<div class="logo">
						<v-img class="img" width="100%" height="100%" aspect-ratio="3.965" cover
							src="https://cdn.sunpump.meme/public/logo/SMOKING_TTYDyu_GBgBz64TWqVz.webp"></v-img>
					</div>
					<div class="meta">
						<div class="name-symbol-creator">
							<span class="name-symbol">
								PhoenixCo SUN ($PHSUN)
							</span>
							<div class="txt t-creator">
								Created by:
								<span>TFYY...TiB5</span>
							</div>
						</div>
						<div class="contract">
							<div class="txt">
								Contract:
								<span>TFaCCmRQYbhNDmZMHTipn77FNKn</span>
								<v-icon class="icon" icon="mdi-floppy"></v-icon>
							</div>
						</div>
						<div class="links">
							<span>
								Related links:
							</span>
							<div class="link">
								<v-icon class="icon" icon="mdi-order-bool-ascending"></v-icon>
								<v-icon class="icon" icon="mdi-refresh"></v-icon>
								<v-icon class="icon" icon="mdi-refresh"></v-icon>
							</div>
						</div>
						<div class="score">
							<span>
								Fun score:
							</span>
							<a-rate :value="5" disabled />
							<span class="class meme-class">
								[Fomo3d Gameplay]
							</span>
						</div>
						<div class="description">
							PhoenixCo SUN is the first MemeCoin backed by PhoenixCo - XPHX Fund. Upcoming Events In
							case of successful token launch on SunSwap: .1~3 Sep 2024| 69,420 $XPHX Airdrop between
							all holders(snapshot). .Allocating 369,420 $XPHX to PHSUN/XPHX V2 and BURN LPs!
						</div>
					</div>
				</div>
				<div class="visualize-mint">
					<div class="visualize">
						<div class="v-contnet">
							<div class="v-item">
								<span class="title">
									Mint Price
								</span>
								<span class="value">
									0.000032 TRX
								</span>
							</div>
							<div class="v-item">
								<span class="title">
									Market Cap
								</span>
								<span class="value">
									$21.59k
								</span>
							</div>
						</div>
						<div class="v-contnet">
							<div class="v-item">
								<span class="title">
									Height Start
								</span>
								<span class="value">
									44815547
								</span>
							</div>
							<div class="v-item">
								<span class="title">
									Height End
								</span>
								<span class="value">
									44816547
								</span>
							</div>
						</div>
						<div class="v-contnet">
							<div class="v-item">
								<span class="title">
									Progress
								</span>
								<a-progress class="value" :percent="50" status="active" strokeColor="#0052ff"
									trailColor="#3b3b49" />
							</div>
						</div>
					</div>
					<div class="m-content">
						<div class="mint-burn">
							<div class="tab">
								<span class="item" :class="isMint?'tab-selected':''" @click="isMint=!isMint">
									Mint
								</span>
								<span class="item" :class="!isMint?'tab-selected':''" @click="isMint=!isMint">
									Burn
								</span>
							</div>
							<div class="input-content">
								<a-input class="input" v-model:value="amount" :bordered="false"
									placeholder="Enter the amount">
									<template #suffix>
										<div class="suffix">
											<span class="symbol">
												TRX
											</span>
											<v-img width="35px" height="35px" aspect-ratio="3.965" cover
												src="https://static.tronscan.org/production/logo/trx.png"></v-img>
										</div>
									</template>
								</a-input>
								<span class="balance">
									Balance:: 1000BNB
								</span>
							</div>
							<div class="toggle">
								<div class="item" @click="amount = 100">100 TRX</div>
								<div class="item" @click="amount = 200">200 TRX</div>
								<div class="item" @click="amount = 300">300 TRX</div>
								<div class="item" @click="amount = 400">400 TRX</div>
							</div>

							<div class="button">
								<v-btn class="btn" rounded="lg">Confirm</v-btn>
							</div>
						</div>
					</div>
				</div>
				<div class="history-holder">
					<div class="history">
						<span class="title">
							Event History
						</span>
						<div class="txs">
							<div class="item">
								<div class="item-title">
									<span class="col address">Address</span>
									<div class="col type">Type</div>
									<div class="col main-token">ETH</div>
									<div class="col token-b">Amount</div>
									<div class="col date">Date</div>
									<div class="col tx">Transaction</div>
								</div>
								<div class="item-meta" v-for="i in 10" :key="i">
									<span class="col address">TVqa...1Ep{{i}}</span>
									<div class="col type">
										{{i%2==0?'mint':'burn'}}
									</div>
									<div class="col main-token">
										0.015
									</div>
									<div class="col token-b">
										19,904,501.55
									</div>
									<div class="col date">6 days ago</div>
									<div class="col tx">2cd34808...004d</div>
								</div>
							</div>
						</div>
					</div>
					<div class="holder">
						<span class="title">
							Holder Distribution
							<span class="holder-number">
								<v-icon icon="mdi-account"></v-icon>
								1000
							</span>
						</span>
						<div class="holders">
							<div class="item">
								<div class="item-title">
									<span class="col address">Address</span>
									<div class="col amount">Amount</div>
									<div class="col p">Percentage</div>
								</div>
								<div class="item-meta" v-for="i in 10" :key="i">
									<span class="col address-p">TVqaadsASE...1d1313a1Ep{{i}}</span>
									<span class="col address-m">TVqa...a1Ep{{i}}</span>
									<div class="col amount">10000.244</div>
									<div class="col p">20.99%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'v-token',
		data() {
			return {
				isMint: true,
				amount: null,
			}
		},
		computed: {
			isMobile() {
				return this.$vuetify.display.mobile;
			},
			address() {
				return this.$route.params.address;
			}
		},
		watch: {
			address(ca) {
				// 如果路由参数变化，更新 userId
				this.address = ca;
			}
		}
	}
</script>

<style lang="scss">
	.token {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content {
			width: calc(100% - 32px);
			max-width: 1240px;
			will-change: transform;
			margin: 20px 0;

			.token-info-pc {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				color: #f5f5f5;
				gap: 30px;

				@media (max-width:768px) {
					gap: 20px;
				}


				.logo-meta-link {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					flex-wrap: wrap;
					gap: 30px;
					padding: 40px 30px;
					border-radius: 10px;
					background-image: url(../../public/img/bg.svg);
					background-position: top right -200%;
					background-size: auto;
					background-repeat: no-repeat;
					box-shadow: 0px 0px 1px 1px rgba(32, 40, 56, 0.5);
					background-color: rgba(32, 40, 56, 0.4);

					@media (max-width:768px) {
						gap: 20px;
						padding: 20px 15px;
					}

					.logo {
						width: 200px;
						height: 200px;

						@media (max-width:768px) {
							width: 120px;
							height: 120px;
						}

						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 8px;
						overflow: hidden;
						box-shadow: 0px 1px 10px rgba(0, 82, 255, .9);
					}

					.meta {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 20px;

						@media (max-width:768px) {
							gap: 8px;
						}

						.name-symbol-creator {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							flex-wrap: wrap;

							.name-symbol {
								font-size: 30px;
								font-weight: bold;

								@media (max-width:768px) {
									font-size: 20px;
								}

							}

							.txt {
								color: #9c9cab;

								span {
									cursor: pointer;
									color: #0052ff;
									text-underline-offset: 3px;
									text-decoration: underline;
								}

								span:hover {
									color: rgba(0, 82, 255, 0.8);
								}
							}
						}

						.contract {
							display: flex;
							flex-direction: row;

							.txt {
								color: #9c9cab;
								font-size: 16px;

								@media (max-width:768px) {
									font-size: 14px;
								}

								span {
									font-weight: normal;
									margin-right: 5px;
									cursor: pointer;
									color: #f5f5f5;
									text-underline-offset: 3px;
									text-decoration: none;
								}

								span:hover {
									color: #e3e3e3;
								}

								.icon {
									font-size: 21px;
									cursor: pointer;

									@media (max-width:768px) {
										display: none;
									}
								}


								.icon:hover {
									color: #f5f5f5;
								}
							}
						}

						.links {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: center;
							flex-wrap: wrap;
							gap: 5px;
							color: #9c9cab;

							font-size: 16px;

							@media (max-width:768px) {
								font-size: 14px;
							}

							.link {
								gap: 8px;
								display: flex;
								flex-direction: row;

								.icon {
									cursor: pointer;
									color: #f5f5f5;
								}

								.icon:hover {
									transform: scale(1.1);
									color: #f3f3f3;
								}
							}

						}

						.score {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: center;
							flex-wrap: wrap;
							gap: 5px;
							color: #9c9cab;
							font-size: 16px;

							span {
								line-height: 18px;
							}

							.class {
								color: #f5f5f5;
								font-weight: normal;
							}

							@media (max-width:768px) {
								font-size: 14px;

								span {
									line-height: 0px;
								}

								.class {
									font-size: 14px;
								}
							}
						}

						.description {
							font-size: 16px;
							color: #9c9cab;

							@media (max-width:768px) {
								font-size: 14px;
							}
						}
					}

				}

				.visualize-mint {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					gap: 30px;

					@media (max-width:768px) {
						gap: 15px;
					}


					.visualize {
						flex: 1.3;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 30px;
						padding: 30px;
						border-radius: 10px;
						background-color: rgba(32, 40, 56, 0.4);

						@media (max-width:768px) {
							gap: 10px;
							padding: 0px;
							background-color: transparent;
						}


						.v-contnet {
							flex: 1;
							width: 100%;
							gap: 25px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;


							@media (max-width:768px) {
								gap: 10px;
							}

							.v-item {
								flex: 1;
								height: 100%;
								display: flex;
								flex-direction: column;
								align-items: start;
								justify-content: center;
								gap: 15px;
								padding: 15px;
								border-radius: 10px;
								background-color: rgba(32, 40, 56, 0.8);

								@media (max-width:768px) {
									gap: 10px;
									padding: 15px;
								}

								.title {
									color: #9c9cab;
									font-size: 15px;
									font-weight: bold;

									@media (max-width:768px) {
										font-size: 14px;
									}

								}

								.value {
									color: #f5f5f5;
									font-size: 20px;
									font-weight: bold;

									@media (max-width:768px) {
										font-size: 17px;
									}
								}
							}
						}
					}

					.m-content {
						flex: 1;
						min-width: 318px;
						padding: 25px;
						border-radius: 10px;
						background-color: rgba(32, 40, 56, 0.4);

						display: flex;
						align-items: center;
						justify-content: center;

						@media (max-width:768px) {
							padding: 20px 15px;
						}

						.mint-burn {
							width: 100%;
							height: 100%;

							.tab {
								width: 100%;
								height: 60px;
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								border-radius: 8px;
								overflow: hidden;
								padding: 5px;
								background-color: rgba(32, 40, 56, 0.8);
								box-shadow: rgba(41, 52, 73, 1.0) 0 0 2px, rgba(41, 52, 73, 1.0) 0 -0.5px inset;

								@media (max-width:768px) {
									height: 45px;
									padding: 0px;
								}

								.item {
									flex: 1;
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 20px;
									font-weight: bold;
									cursor: pointer;
									border-radius: 8px;

									@media (max-width:768px) {
										font-size: 16px;
									}
								}
							}

							.input-content {
								margin-top: 25px;
								width: 100%;
								height: 100px;
								border-radius: 8px;
								padding: 10px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								border: 1px solid rgba(44, 56, 79, 1.0);
								background-color: rgba(32, 40, 56, 0.8);


								.input {
									color: #9c9cab;

									.suffix {
										display: flex;
										flex-direction: row;
										align-items: center;
										justify-content: center;

										.symbol {
											font-size: 20px;
											font-weight: bold;
											margin-right: 10px;
										}
									}
								}

								.ant-input {
									color: #f5f5f5;
									font-size: 22px;
									font-weight: 600;
								}

								.balance {
									font-size: 14px;
									padding: 10px;
									color: #9c9cab;
								}


								@media (max-width:768px) {
									margin-top: 20px;
									padding: 5px;

									.input {
										.suffix {
											.symbol {
												font-size: 18px;
											}
										}
									}

									.ant-input {
										font-size: 20px;
									}

									.balance {
										font-size: 14px;
									}

								}

							}

							.toggle {
								width: 100%;
								margin-top: 10px;
								display: flex;
								flex-direction: row;
								gap: 10px;

								.item {
									flex: 1;
									text-align: center;
									cursor: pointer;
									border-radius: 3px;
									padding: 8px 12px;
									color: #f5f5f5;
									background-color: rgba(32, 40, 56, 0.8);
								}

								.item:hover {
									background-color: rgba(49, 61, 86, 0.8);
								}

								@media (max-width:768px) {
									.item {
										font-size: 13px;
										padding: 8px;
									}
								}
							}

							.button {
								margin-top: 30px;
								width: 100%;

								.btn {
									width: 100%;
									height: 4rem;
									font-size: 22px;
									font-weight: bold;
									color: #fff;
									background-color: #0052ff;
								}

								@media (max-width:768px) {
									margin-top: 20px;

									.btn {
										font-size: 18px;
										height: 3.3rem;
									}
								}
							}

						}
					}
				}

				.history-holder {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					gap: 30px;

					@media (max-width:768px) {
						gap: 15px;
					}


					.history {
						flex: 1.3;
						min-width: 318px;
						
						display: flex;
						flex-direction: column;
						gap: 20px;

						.title {
							font-size: 25px;
							font-weight: bold;
							color: #F5F5F5;
							display: flex;
							align-items: center;
							gap: 10px;
						}

						.txs {
							flex: 1;
							border-radius: 10px;
							overflow: hidden;
							background-color: rgba(32, 40, 56, 0.4);
							padding: 0px 30px;

							@media (max-width:768px) {
								padding: 20px 15px;
							}

							.item {
								width: 100%;
								display: flex;
								flex-direction: column;


								.item-title,
								.item-meta {
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: space-between;
									height: 55px;
									cursor: pointer;
									color: #F5F5F5;

									.col {
										flex: 1;
										height: 100%;
										display: flex;
										align-items: center;
										justify-content: center;
										border-bottom: 1px solid rgba(33, 42, 59, .8);
										font-size: 15px;

										@media (max-width:768px) {
											font-size: 14px;
										}

									}

									.address {
										flex: none;
										width: 100px;
										display: flex;
										align-items: center;
										justify-content: flex-start;
										color: rgba(0, 82, 255, 0.8);
										font-weight: bold;
									}

									.main-token {
										flex: none;
										width: 80px;
										
										@media (max-width:1210px) {
											display: none;
										}
										
									}

									.type {
										flex: none;
										width: 60px;
										@media (max-width:1210px) {
											flex: 0.5;
											display: flex;
											align-items: center;
											justify-content: flex-end;
										}
									}
									
									.token-b{
										@media (max-width:1210px) {
											display: flex;
											align-items: center;
											justify-content: flex-end;
										}
									}

									.date {
										@media (max-width:1210px) {
											display: none;
										}
									}

									.tx {
										display: flex;
										align-items: center;
										justify-content: flex-end;
										color: rgba(0, 82, 255, 0.8);
										font-weight: bold;
										
										@media (max-width:1210px) {
											display: none;
										}
									}

								}

								.item-title {
									.col {
										font-weight: 600;
										color: #9c9cab;
									}
								}

							}
						}

					}

					.holder {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 20px;

						.title {
							font-size: 25px;
							font-weight: bold;
							color: #F5F5F5;
							display: flex;
							align-items: center;
							gap: 10px;

							.holder-number {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								font-size: 17px;
							}

							@media (max-width:768px) {
								font-size: 20px;

								.holder-number {
									font-size: 15px;
								}
							}

						}

						.holders {
							flex: 1;
							border-radius: 10px;
							background-color: rgba(32, 40, 56, 0.4);
							padding: 0px 30px;

							@media (max-width:768px) {
								padding: 0px 15px;
							}

							.item {
								width: 100%;
								display: flex;
								flex-direction: column;


								.item-title,
								.item-meta {
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: center;
									height: 55px;
									cursor: pointer;
									color: #F5F5F5;

									.col {
										flex: 1;
										height: 100%;
										display: flex;
										align-items: center;
										justify-content: center;
										border-bottom: 1px solid rgba(33, 42, 59, .8);
										font-size: 15px;

										@media (max-width:768px) {
											font-size: 14px;
										}

									}

									.address {
										display: flex;
										align-items: center;
										justify-content: flex-start;
									}

									.address-p,
									.address-m {
										display: flex;
										align-items: center;
										justify-content: flex-start;
										color: rgba(0, 82, 255, 0.8);
										font-weight: bold;
									}

									.address-p {
										@media (max-width:768px) {
											display: none;
										}
									}

									.address-m {
										@media (min-width:768px) {
											display: none;
										}
									}

									.amount {
										flex: none;
										width: 100px;
									}

									.p {
										flex: none;
										width: 100px;
										display: flex;
										align-items: center;
										justify-content: flex-end;
										color: rgba(0, 82, 255, 0.8);
										font-weight: bold;
									}

								}

								.item-title {
									.col {
										font-weight: 600;
										color: #9c9cab;
									}
								}

							}
						}

					}
				}
			}

		}
	}

	@media (max-width:768px) {
		.t-creator {
			position: absolute;
			top: 60px;
			right: 15px;
			font-size: 14px;
		}

		.meme-class {
			position: absolute;
			top: 90px;
			right: 15px;
			font-size: 15px !important;
		}
	}

	.ant-progress-text {
		font-weight: normal;
		color: #f5f5f5 !important;
	}

	.ant-progress-line {
		margin: 0px !important;
	}

	.tab-selected {
		background-color: #0052ff;
		transition: 0.5s;
	}

	.ant-input::placeholder {
		color: #9c9cab;
		font-size: 22px;
		font-weight: 600;

		@media (max-width:768px) {
			font-size: 20px;
		}

	}


	.row {
		cursor: pointer;
	}
</style>