<template>
	<div class="home">
		<div class="content">
			<div class="t">
				<div class="t-left">
					<v-img v-if="isMobile" class="img" width="360" aspect-ratio="16/9" src="./img/fomo3d.sm.png" />
					<v-img v-else class="img" width="580" aspect-ratio="16/9" src="./img/fomo3d.lg.png" />

					<p class="title">The first meme launch platform using fomo3d gameplay on Base.</p>
					<p class="mt-2">
						Let's fly to the moon！
						<a href="" class="link mt-1">How it works?</a>
					</p>
					<div class="mb-2">
						<v-btn class="btn" rounded="lg" @click="$router.push('/launch')">Create Token</v-btn>
					</div>
				</div>
				<div class="t-right">
					<div class="r-b">
						<div class="ad">
							<Splide class="slides" ref="splideRef" :options="options" aria-label="My Favorite Images">
								<SplideSlide v-for="i in 24" :key="i">
									<div class="slide-item">
										item{{i}}
									</div>
								</SplideSlide>
							</Splide>
						</div>
					</div>
				</div>
			</div>

			<div class="fliter">
				<div class="search">
					<input type="text" placeholder="Search for tokens" />
				</div>
				<div class="menu">
					<div class="menu-class">
						<a-dropdown>
							<a class="dropdown" @click.prevent>
								Filter
								<v-icon icon="mdi-chevron-down"></v-icon>
							</a>
							<template #overlay>
								<a-menu>
									<a-menu-item style="font-size: 16px;" key="1">Minting</a-menu-item>
									<a-menu-item style="font-size: 16px;" key="2">Waiting</a-menu-item>
								</a-menu>
							</template>
						</a-dropdown>
					</div>

					<div class="menu-control">
						<v-btn class="btn" density="default" icon="mdi-order-bool-ascending"></v-btn>
						<v-btn class="btn" density="default" icon="mdi-refresh"></v-btn>
					</div>
				</div>
			</div>

			<div class="memes">
				<div class="item" v-for="i in 10" :key="i" @click="goTokenPage(i)">
					<div class="logo">
						<v-img class="img" width="100%" height="245px" aspect-ratio="3.965" cover
							src="https://cdn.sunpump.meme/public/logo/Trump_TUutWL_V8FyTm3ZhaAq.jpeg"></v-img>
					</div>
					<div class="creator">
						<div class="txt">
							Created By:
							<span>TEbN...uX6v</span>
						</div>
						<div class="link">
							<v-icon class="icon" icon="mdi-order-bool-ascending"></v-icon>
							<v-icon class="icon" icon="mdi-refresh"></v-icon>
							<v-icon class="icon" icon="mdi-refresh"></v-icon>
						</div>
					</div>
					<div class="meta">
						<div class="name">
							Sun Wen
							<span>($WEN)</span>
						</div>
						<div class="description">
							The Sri Lankan Junglefowl, our vibrant national bird, symbolizes our rich heritage. This
							coin highlights Sri Lanka's unique culture, blending tradition and innovation to boost
							tourism and showcase our island's billion-dollar potential.
						</div>
					</div>
					<div class="progress">
						<div>
							Market Cap：
							<span>$10.94k</span>
						</div>
						<a-progress :percent="50" status="active" strokeColor="#0052ff" trailColor="#2b2b35" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'v-home',
		data() {
			return {
				items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
				options: {
					rewind: true,
					perPage: 1,
					gap: '10px',
					pagination: false,
					arrows: true,
					autoplay: true,
				},
			}
		},
		computed: {
			isMobile() {
				return this.$vuetify.display.mobile;
			}
		},
		mounted() {},
		unmounted() {

		},
		methods: {
			goTokenPage(address) {
				this.$router.push(`/token/${address}`)
			}
		}
	}
</script>

<style lang="scss">
	.home {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content {
			width: calc(100% - 32px);
			max-width: 1240px;
			will-change: transform;
			margin: 10px 0;

			@media (max-width:768px) {
				margin: 30px 0;
			}

			.t {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				aspect-ratio: 3.659;
				gap: 10px;
				
				.t-left {
					flex: 1;
					height: 300px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;

					.img {
						margin: 0px 0px 15px 0px;
					}

					.title {
						color: #F5F5F5;
						margin: 0;
					}

					p {
						font-size: 22px;
						font-weight: bold;
						color: #F5F5F5;

						@media (max-width:768px) {
							font-size: 21px;
						}

						a {
							color: #0052ff;
						}
					}


					.btn {
						width: 120;
						height: 50px;
						font-size: 20px;
						font-weight: bold;
						color: #FFFFFF;
						background-color: #0052ff;

						@media (max-width:768px) {
							// display: none;
						}
					}
				}

				.t-right {
					height: 300px;

					@media (max-width:768px) {
						height: 130px;
						display: none;
					}

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;

					.r-t {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						max-width: 500px;

						.badge {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							background: linear-gradient(90.21deg, #ff6b6b -98.85%, #0052ff 111.64%);
							box-shadow: 0 2px 4.05px #000;
							border-radius: 5px;
							width: 100%;
							max-width: 370px;
							height: 40px;
							gap: 0.3125rem;

							padding: 10px;

							.badge-text {
								flex: 1;
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								text-align: center;
								font-weight: bold;
							}
						}
					}

					.r-b {
						flex: 1;
						margin: 20px 0px;
						width: 100%;
						max-width: 500px;

						.ad {
							width: 100%;
							height: 100%;
							border-radius: 5px;
							border: 1px solid red;
							overflow: hidden;

							.slides {
								flex: 1;
								height: 100%;
								display: flex;

								@media (max-width:768px) {
									display: none;
								}

								.slide-item {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100%;
								}
							}

						}
					}
				}

				@media (max-width: 768px) {
					.t-left {
						width: 100%;
						max-width: 370px;

						a {
							display: block;
						}
					}
				}
			}

			.fliter {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				background-color: #101010;
				position: sticky;
				top: 60px;
				z-index: 9999;
				box-shadow: -2px 2px 2px 8px #101010;
				transition: .3s ease-in-out;
				margin-top: 10px;

				@media (max-width:768px) {
					position: unset;
				}

				.search {
					margin: 25px 0px 10px 0px;

					@media (max-width:768px) {
						min-width: 100%;
						margin: 21px 0px 10px 0px;
					}

					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					border-radius: 10px;
					border: 1px solid #43444d;
					flex: 1;
					height: 42px;
					background-color: #181818;
					border-radius: 8px;
					overflow: hidden;
					padding: 0px 15px;

					input {
						color: #F5F5F5;
						width: 100%;
					}
				}

				.menu {
					margin: 20px 0px 10px 0px;

					@media (max-width:768px) {
						margin: 10px 0px;
					}

					flex: 1;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					gap: 20px;

					.menu-class {
						.dropdown {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 200px;
							height: 42px;
							font-size: 16px;
							background-color: #181818;
							color: #FFFFFF;
							border-radius: 8px;
							padding: 0 10px 0 15px;
							border: 1px solid #43444d;
						}

						.dropdown-item {
							height: 30px;
							font-size: 18px;
						}
					}


					.menu-control {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 20px;

						.btn {
							width: 40px;
							height: 40px;
							background: transparent;
							border: 1px solid var(--brand-stroke);
							border-radius: 10px;
							transition: all .3s;
							background-color: transparent;
							color: #FFFFFF;
							box-shadow: #FFFFFF 0 0 2px, #FFFFff80 0 -0.5px inset;
						}
					}

				}

				@media (max-width:768px) {
					.menu {
						justify-content: flex-start;
					}
				}
			}

			.memes {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: start;
				flex-wrap: wrap;
				gap: 26px;
				margin: 5px 0px;

				.item {
					margin: 5px 0px;
					width: 290px;
					box-sizing: border-box;
					box-shadow: 0px 1px 2px rgba(0, 102, 255, 1);

					@media (max-width:768px) {
						min-width: 100%;
						background: linear-gradient(153.51deg, #101010 70.81%, #0052ff 133.08%);
						background-size: cover;
						background-repeat: no-repeat;
					}

					display: flex;
					flex-direction: column;
					height: 465px;
					border-radius: 10px;
					overflow: hidden;
					cursor: pointer;

					.logo {
						width: 100%;

						.img {}
					}

					.creator {
						width: 100%;
						padding: 20px 20px 0px 20px;
						margin-bottom: 10px;
						color: #F5F5F5;
						font-weight: 500;
						font-size: 13px;

						@media (max-width:768px) {
							font-size: 14px;
						}

						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.txt {
							flex: 2;

							span {
								cursor: pointer;
								color: #0052ff;
								text-underline-offset: 3px;
								text-decoration: underline;
							}

							span:hover {
								color: rgba(0, 102, 255, 0.8);
							}
						}

						.link {
							gap: 8px;
							display: flex;
							flex-direction: row;
							align-items: end;
							justify-content: space-between;

							.icon {
								width: 16px;
								height: 16px;

								@media (max-width:768px) {
									width: 18px;
									height: 18px;
								}

								cursor: pointer;
								color: #9c9cab;
							}

							.icon:hover {
								transform: scale(1.1);
								color: #FFFFFF;
							}
						}
					}

					.meta {
						width: 100%;
						color: #FFFFFF;
						padding: 0px 20px;
						display: flex;
						flex-direction: column;

						.name {
							font-weight: 600;
							font-size: 15px;
							line-height: 19.6px;
							white-space: nowrap;
							margin-bottom: 10px;

							@media (max-width:768px) {
								font-size: 16px;
							}
						}

						.description {
							font-weight: 400;
							font-size: 12px;

							@media (max-width:768px) {
								font-size: 13px;
							}

							line-height: 16px;
							color: #999;
							width: 100%;
							height: 65px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 4;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					.progress {
						width: 100%;
						color: #999;
						margin-top: 20px;
						font-size: 13px;
						font-weight: 500;
						line-height: 16.8px;
						padding: 0px 20px;

						span {
							color: #F5F5F5;
						}
					}
				}

				.item:hover {
					background: linear-gradient(153.51deg, #101010 70.81%, #0052ff80 133.08%);
					background-size: cover;
					background-repeat: no-repeat;
				}
			}

		}
	}


	.flash {
		animation: shadowFlash 0.1s infinite;
	}

	@keyframes shadowFlash {
		0% {
			box-shadow: 0 2px 4.05px rgba(0, 102, 255, 1);
		}

		10% {
			box-shadow: 0 2px 4.05px rgba(255, 0, 0, 1);
		}

		20% {
			box-shadow: 0 2px 4.05px rgba(0, 102, 255, 1);
		}

		30% {
			box-shadow: 0 2px 4.05px rgba(255, 0, 0, 1);
		}

		40% {
			box-shadow: 0 2px 4.05px rgba(0, 102, 255, 1);
		}

		50% {
			box-shadow: 0 2px 4.05px rgba(255, 0, 0, 1);
		}

		60% {
			box-shadow: 0 2px 4.05px rgba(0, 102, 255, 1);
		}

		70% {
			box-shadow: 0 2px 4.05px rgba(255, 0, 0, 1);
		}

		80% {
			box-shadow: 0 2px 4.05px rgba(0, 102, 255, 1);
		}

		90% {
			box-shadow: 0 2px 4.05px rgba(255, 0, 0, 1);
		}

		100% {
			box-shadow: 0 1px 4.05px rgba(0, 102, 255, 1);
		}
	}


	input:focus,
	textarea:focus {
		caret-color: #F5F5F5;
		outline: red;
	}
</style>