<template>
	<div class="fomo3d">
		
	</div>
</template>

<script>
	export default {
		name: 'v-fomo3d'
	}
</script>

<style>
</style>