<template>
	<div>
		<v-header></v-header>
		<router-view ref="currentRoute"></router-view>
		<VFooterVue></VFooterVue>

		<v-layout class="bottom-nav">
			<v-bottom-navigation class="uni-background text-inactive-color" :active="isMobile" color="#0066FF" grow>
				<v-btn class="bottom-nav-text" @click.stop="goPage('/')">
					<v-icon class="icon">mdi-history</v-icon>
					HOME
				</v-btn>

				<v-btn class="bottom-nav-text" @click.stop="goPage('/launch')">
					<v-icon class="icon">mdi-heart</v-icon>
					LAUNCH
				</v-btn>

				<v-btn class="bottom-nav-text" @click.stop="goPage('/fomo3d')">
					<v-icon class="icon">mdi-heart</v-icon>
					FOMO3D
				</v-btn>

				<v-btn class="bottom-nav-text" @click.stop="goPage('/docs')">
					<v-icon class="icon">mdi-map-marker</v-icon>
					DOCS
				</v-btn>
			</v-bottom-navigation>
		</v-layout>
	</div>
</template>

<script>
	import {
		useIsMobileStore
	} from './stores/stores';

	import VHeader from './components/V-Header.vue'
	import VFooterVue from './components/V-Footer.vue';

	export default {
		name: 'App',
		components: {
			VHeader,
			VFooterVue
		},
		data() {
			return {
				mobileStore: useIsMobileStore(),
			}
		},
		mounted() {
			this.checkMobile(this.isMobile)
		},
		computed: {
			isMobile() {
				return this.$vuetify.display.mobile;
			}
		},
		watch: {
			isMobile(val) {
				this.checkMobile(val)
			}
		},
		methods: {
			checkMobile(val) {
				this.mobileStore.updateStore(val)
			},
			goPage(url) {
				this.$router.push(url)
			}
		}
	}
</script>

<style lang="scss">
	html,
	body {
		background-color: #101010;
	}

	.nav {}

	.main {
		height: 100vh;
	}

	.uni-background {
		background-color: #101010 !important;
	}

	.uni-pointer {
		cursor: pointer;
	}

	.list-item-menu {
		padding: 10px 0px;
	}

	.text-active-color {
		color: #F5F5F5 !important;
	}

	.text-inactive-color {
		color: #B2B2BD !important;
	}


	.bottom-nav {
		.bottom-nav-text {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0px;
			margin: 0px;

			flex: 1;
			font-size: 0.8125rem;
			font-weight: 600;

			.icon {
				font-size: 23px;
				margin-bottom: 3px;
			}
		}
	}


	.text-left {
		text-align: left;
	}


	.text-right {
		text-align: right;
	}

	.text-center {
		text-align: center;
	}
</style>


<style scoped>
	::v-deep .v-list-item-title {
		font-size: 0.875rem;
	}
</style>